exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-before-the-session-tsx": () => import("./../../../src/pages/before-the-session.tsx" /* webpackChunkName: "component---src-pages-before-the-session-tsx" */),
  "component---src-pages-book-now-tsx": () => import("./../../../src/pages/book-now.tsx" /* webpackChunkName: "component---src-pages-book-now-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-services-tsx": () => import("./../../../src/pages/our-services.tsx" /* webpackChunkName: "component---src-pages-our-services-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */)
}

